import * as React from "react";
import { FormattedMessage, useIntl } from "gatsby-plugin-react-intl";
import TextPage, { TextBlock } from '../components/text-page';
import commonMessageValues from '../templates/formatjs-message-values';

const TransactionAuthorization = (): JSX.Element => {
  const intl = useIntl();
  const messages = intl.messages;
  const textBlocks: TextBlock[] = [{
    header: `${messages.transaction_authorization_clearing_and_settlement}`,
    message: (
      <>
        <b>{messages.authorization} </b>
        <div className={(intl.locale === "en" ? "text-ltr" : "text-rtl")}>
          <FormattedMessage id='message_authorization' values={commonMessageValues}/>
        </div>
        <b>{messages.clearing_and_settlement} </b>
        <div className={(intl.locale === "en" ? "text-ltr" : "text-rtl")}>
          <FormattedMessage id='message_clearing_and_settlement' values={commonMessageValues}/>
        </div>
      </>
    )
  }];

  return (<TextPage locale={intl.locale} title={`${messages.transaction_authorization_clearing_and_settlement}`}
                    textBlocks={textBlocks}/>);
};

export default TransactionAuthorization;